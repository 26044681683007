import { auth } from '@osrdata/app_core'
import {
  ReactElement,
  StrictMode,
  Suspense,
  useEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Redirect,
  Route,
  Router, Switch,
} from 'react-router-dom'

import CircularProgress from '@mui/material/CircularProgress'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import AccessDenied from 'AccessDenied'
import 'App.scss'
import {
  ADMIN_HOMEPATH, ADMIN_ROUTES, MANAGER_HOMEPATH, MANAGER_ROUTES, OPERATOR_HOMEPATH,
  OPERATOR_ROUTES, RouteType, SUPERVISOR_HOMEPATH, SUPERVISOR_ROUTES,
} from 'Routes'
import { RootState } from 'Store'
import Cgu from 'components/Cgu/Cgu'
import Loader from 'components/Common/Loader'
import TopBar from 'components/TopBar/TopBar'
import history from 'customHistory'
import { isAdmin, isManager, isSupervisor } from 'helpers/permissions'

const APP_NAME = 'Midi Ve'

const ACCESS_PERM = 'midi-ve::OPERATOR'

export default function App(): ReactElement {
  const dispatch = useDispatch()
  const { isLogged, appPermissions } = useSelector((state: RootState) => state.user)
  const [isLoading, setIsLoading] = useState(true)
  const [cgu, setCgu] = useState<boolean>(JSON.parse(localStorage.getItem('cguAccepted')!) || false)
  const [homePath, setHomePath] = useState<string | undefined>(undefined)
  const [routes, setRoutes] = useState<Array<RouteType> | undefined>(undefined)
  const [accessDenied, setAccessDenied] = useState(false)

  const getUserParams = (): void => {
    switch (true) {
      case isAdmin():
        setHomePath(ADMIN_HOMEPATH)
        setRoutes(ADMIN_ROUTES)
        break
      case isSupervisor():
        if (isManager()) {
          setHomePath(SUPERVISOR_HOMEPATH.concat(MANAGER_HOMEPATH))
          setRoutes(SUPERVISOR_ROUTES.concat(MANAGER_ROUTES))
          return
        }
        setHomePath(SUPERVISOR_HOMEPATH)
        setRoutes(SUPERVISOR_ROUTES)
        break
      case isManager():
        setHomePath(MANAGER_HOMEPATH)
        setRoutes(MANAGER_ROUTES)
        break
      default:
        setHomePath(OPERATOR_HOMEPATH)
        setRoutes(OPERATOR_ROUTES)
        break
    }
  }

  useEffect(() => {
    dispatch(auth.attemptLoginOnLaunch())
  }, [])

  useEffect(() => {
    if (isLogged && appPermissions.length > 0) {
      getUserParams()
      if (!appPermissions.includes(ACCESS_PERM)) {
        setAccessDenied(true)
      }
      setIsLoading(false)
    }
  }, [isLogged, appPermissions])

  const displayApp = !isLoading && homePath && routes

  const handleOpen = () => {
    localStorage.setItem('cguAccepted', JSON.stringify(!cgu))
    setCgu(!cgu)
  }
  const renderApp = () => {
    if (!cgu) return <Cgu handleOpen={handleOpen} isOpen={cgu} />
    if (!displayApp) return <Loader message="Chargement" />
    return (
      <Router history={history}>
        <Switch>
          {routes.map(route => (
            <Route exact key={route.path} component={route.component} path={route.path} />
          ))}
          <Redirect to={homePath} />
        </Switch>
      </Router>
    )
  }

  return (
    <StrictMode>
      <LocalizationProvider dateAdapter={AdapterLuxon} locale="fr">
        <Suspense fallback={<CircularProgress />}>
          <TopBar appName={APP_NAME} acceptedCGU={cgu} />
          {accessDenied ? <AccessDenied /> : (
            <div className="d-flex app-container">
              {renderApp()}
            </div>
          )}
        </Suspense>
      </LocalizationProvider>
    </StrictMode>
  )
}
